import { Component, Input, OnInit } from '@angular/core'
import Passkey from '@account/Passkey'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
	selector: 'rename-passkey-dialog',
	templateUrl: './RenamePasskeyDialog.Component.html',
})
export class RenamePasskeyDialogComponent implements OnInit {
	@Input() public passkey: Passkey

	public name: string

	constructor(
		private readonly dialogRef: MatDialogRef<RenamePasskeyDialogComponent>,
	) {}

	public ngOnInit() {
		this.name = this.passkey.name
	}

	public confirmAndClose = () => {
		this.dialogRef.close({
			...this.passkey,
			name: this.name,
		})
	}
}
