import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'

@Injectable()
export class WebAuthnService extends BaseService<any> {
	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(httpClient, paginationService)
	}

	public getOptions = async (): Promise<any> => {
		return await this.get({ url: '/api/account/webauthn' })
	}

	public verify = async (json: any): Promise<any> => {
		return await this.post('/api/account/webauthn/verify', { ...json })
	}

	public login = async (json: any): Promise<any> => {
		return await this.post('/api/account/webauthn/login', { ...json })
	}
}
