import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import Passkey from '@account/Passkey'

@Injectable()
export class PasskeysService extends BaseService<Passkey> {
	protected readonly endpoint = '/api/account/passkeys'

	public update = async (passkey: Passkey): Promise<Passkey> => {
		const response = await this.put(`${this.endpoint}/${passkey.id}`, {
			passkey,
		})

		return this.mapToObject(response)
	}

	public deleteKey = async (passkey: Passkey): Promise<void> => {
		await this.delete(`${this.endpoint}/${passkey.id}`)
	}

	public mapToObject = (data: any): Passkey => {
		return new Passkey(data.id, data.name, data.created_at, data.updated_at)
	}
}
