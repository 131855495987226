<h4 [ngStyle]="{ color: '#00acd4' }">Account Management</h4>
<form [formGroup]="form" (ngSubmit)="login(email.value, password.value)">
	<div>
		<text-input
			#email
			type="text"
			name="email"
			placeHolder="Email"
			formControlName="email"
			[error]="formErrors.email"
			[form]="form"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<text-input
			#password
			placeHolder="Password"
			formControlName="password"
			type="password"
			[error]="formErrors.password"
			[form]="form"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<flat-button [styles]="{ width: '100%' }" color="primary">Login</flat-button>
	</div>
</form>
<flat-button [styles]="{ width: '100%', 'margin-top': '20px' }" color="primary" (click)="startWebAuthn()">Use Passkeys</flat-button>
<form-error *ngIf="error" [error]="error"></form-error>
<div class="auth-links">
	<div *ngIf="displayRegister">
		<a routerLink="/register">Don’t have an account?</a>
	</div>
	<div>
		<a href="/school">I want to manage my school</a>
	</div>
	<div>
		<a routerLink="/forgotten-password">Forgotten Password</a>
	</div>
</div>
