import Filterable from '@ui/Filterable'

export default class Passkey implements Filterable {
	constructor(
		public id: number,
		public name: string,
		public created_at: string,
		public updated_at: string,
	) {}

	public getFilterName(): string {
		return 'passkey'
	}

	public toFilter() {
		return {}
	}

	public reset(): Filterable {
		return new Passkey(undefined, undefined, undefined, undefined)
	}
}
