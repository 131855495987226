import {
	NgModule,
	enableProdMode,
	APP_INITIALIZER,
	ErrorHandler,
} from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpErrorResponse,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http'
import { CommonModule } from '@angular/common'
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'

import { routing, appRoutingProviders } from '@account/config/routing'
import { AppComponent } from '@account/Components/App.Component'
import { IndexComponent } from '@account/Components/Index/IndexComponent'
import { PageNotFoundComponent } from '@account/Components/PageNotFound'
import { RegisterComponent } from '@account/Components/Auth/Register/Register.Component'
import { MatMenuModule } from '@angular/material/menu'
import { UiModule } from '@ui/config/module'
import { LoginComponent } from '@account/Components/Auth/Login/Login.Component'
import HttpError from '@ui/HttpError'
import AppErrorHandler from '@account/AppErrorHandler'
import { PermissionsService } from '@account/Services/PermissionsService'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { AuthenticatedHttpService } from '@account/Services/AuthenticatedHttpService'
import { AccountService } from '@account/Services/AccountService'
import { AppService } from '@account/Services/AppService'
import { CartService } from '@account/Services/CartService'
import { ResetPasswordComponent } from '@account/Components/Auth/ResetPassword/ResetPassword.Component'
import { LoggedOutGuard } from '@account/Services/Guards/LoggedOutGuard'
import { LoggedInGuard } from '@account/Services/Guards/LoggedInGuard'
import { SubRouterComponent } from '@account/Components/SubRouter.Component'
import { DashboardComponent } from '@account/Components/Auth/Dashboard/Dashboard.Component'
import { ClassService } from '@account/Services/ClassService'
import { ClassesComponent } from '@account/Components/Classes/Classes.Component'
import { ClassComponent } from '@account/Components/Classes/Class/Class.Component'
import { PageComponent } from '@account/Components/ui/Page/Page.Component'
import { ClassInfoComponent } from '@account/Components/Classes/Class/Info/ClassInfo.Component'
import { DateTimeComponent } from '@account/Components/ui/DateTime/DateTimeComponent'
import { CurrencyComponent } from '@account/Components/ui/Currency/CurrencyComponent'
import { CategoriesComponent } from '@account/Components/Categories/Categories.Component'
import { CategoryService } from '@account/Services/CategoryService'
import { CategoryComponent } from '@account/Components/Categories/Category/Category.Component'
import { ClassesTableComponent } from '@account/Components/Classes/Table/ClassesTable.Component'
import { CartComponent } from '@account/Components/Cart/Cart.Component'
import { CheckoutComponent } from '@account/Components/Checkout/Checkout.Component'
import { LoginFormComponent } from '@account/Components/Auth/Login/Form/LoginForm.Component'
import { MatIconModule } from '@angular/material/icon'
import { MatBadgeModule } from '@angular/material/badge'
import { RegisterFormComponent } from '@account/Components/Auth/Register/Form/RegisterForm.Component'
import { CheckoutAddressComponent } from '@account/Components/Checkout/Address/CheckoutAddress.Component'
import { CheckoutListAddressesComponent } from '@account/Components/Checkout/Address/CheckoutListAddresses.Component'
import { AddressService } from '@account/Services/AddressService'
import { CheckoutItemsComponent } from '@account/Components/Checkout/Items/CheckoutItems.Component'
import { CheckoutPaymentFormComponent } from '@account/Components/Checkout/Payment/CheckoutPaymentForm.Component'
import { OrderService } from '@account/Services/OrderService'
import { PerformerService } from '@account/Services/PerformerService'
import { PerformersAutocompleteComponent } from '@account/Components/Performers/Autocomplete/PerformersAutocomplete.Component'
import { CheckoutGuard } from '@account/Services/Guards/CheckoutGuard'
import { PerformersComponent } from '@account/Components/Performers/Performers.Component'
import { PerformerComponent } from '@account/Components/Performers/Performer/Performer.Component'
import { PerformerClassesComponent } from '@account/Components/Performers/Performer/Classes/PerformerClasses.Component'
import { CreatePerformerComponent } from '@account/Components/Performers/Create/CreatePerformer.Component'
import { CreatePerformerDialogComponent } from '@account/Dialogs/Performers/CreatePerformerDialog.Component'
import { OrderComponent } from '@account/Components/Orders/Order/Order.Component'
import { CartQuickPayComponent } from '@account/Components/Cart/QuickPay/CartQuickPay.Component'
import { OrdersComponent } from '@account/Components/Orders/Orders.Component'
import { OrderItemsComponent } from '@account/Components/Orders/Order/OrderItems.Component'
import { OrderPaymentsComponent } from '@account/Components/Orders/Order/OrderPayments.Component'
import { ForgottenPasswordComponent } from '@account/Components/Auth/ForgottenPassword/ForgottenPassword.Component'
import { ConfirmDeleteDialogComponent } from '@account/Dialogs/ConfirmDeleteDialog.Component'
import { OrderItemService } from '@account/Services/OrderItemService'
import { SchoolsService } from '@account/Services/SchoolsService'
import { SchoolsRadioListComponent } from '@account/Components/Schools/Select/SchoolsRadioList.Component'
import { EntriesComponent } from '@account/Components/Entries/Entries.Component'
import { EntriesTableComponent } from '@account/Components/Entries/Table/EntriesTable.Component'
import { AddToCartComponent } from '@account/Components/Cart/Add/AddToCart.Component'
import { CartGuard } from '@account/Services/Guards/CartGuard'
import { OrderGuard } from '@account/Services/Guards/OrderGuard'
import { NavComponent } from '@account/Components/Nav/Nav.Component'
import { EntryComponent } from '@account/Components/Entries/Entry/Entry.Component'
import { WaitingListService } from '@account/Services/WaitingListService'
import { WaitingListsComponent } from '@account/Components/WaitingLists/WaitingLists.Component'
import { WaitingListGuard } from '@account/Services/Guards/WaitingListGuard'
import { PaymentMethodService } from '@account/Services/PaymentMethodService'
import { PaymentMethodsComponent } from '@account/Components/PaymentMethods/PaymentMethods.Component'
import { AddressesComponent } from '@account/Components/Addresses/Addresses.Component'
import { PerformerInfoComponent } from '@account/Components/Performers/Performer/PerformerInfo.Component'
import { PerformerSchoolsComponent } from '@account/Components/Performers/Performer/PerformerSchools.Component'
import { PerformerEntriesComponent } from '@account/Components/Performers/Performer/PerformerEntries.Component'
import { PerformerQuickAddComponent } from '@account/Components/Performers/Performer/PerformerQuickAdd.Component'
import { SecurityComponent } from '@account/Components/Security/Security.Component'
import { TwoFactorChallengeComponent } from '@account/Components/Auth/Login/Form/TwoFactorChallenge.Component'
import { ConfirmAccountDetailsDialogComponent } from '@account/Dialogs/Account/ConfirmAccountDetailsDialog.Component'
import { PasskeysComponent } from '@account/Components/Passkeys/Passkeys.Component'
import { RenamePasskeyDialogComponent } from '@account/Dialogs/Security/RenamePasskeyDialog.Component'
import { PasskeysService } from '@account/Services/PasskeysService'
import { WebAuthnService } from '@account/Services/WebAuthnService'

import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { Angulartics2Module } from 'angulartics2'

if (process.env.NODE_ENV === 'production') {
	enableProdMode()
}

const Initialiser = {
	provide: APP_INITIALIZER,
	useFactory:
		(
			permissionService: PermissionsService,
			systemConfigService: SystemConfigService,
			cartService: CartService,
		) =>
		async () => {
			try {
				await permissionService.getPermissions()
				await systemConfigService.getConfig()
				if (
					permissionService.account &&
					systemConfigService.config.checkoutEnabled
				) {
					await cartService.getCart()
				}
				if (process.env.NODE_ENV === 'production') {
					Sentry.init({
						dsn: systemConfigService.config.sentryDsn,
						integrations: [new BrowserTracing()],
						release: systemConfigService.config.sentryRelease,
					})
				}
			} catch (error) {
				if (
					!(error instanceof HttpError) &&
					!(error instanceof HttpErrorResponse)
				) {
					throw error
				}
			}
		},
	deps: [PermissionsService, SystemConfigService, CartService, HttpClient],
	multi: true,
}

const HttpListener = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthenticatedHttpService,
	multi: true,
}

const BoundErrorhandler = {
	provide: ErrorHandler,
	useClass: AppErrorHandler,
}

@NgModule({
	imports: [
		routing,
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatDialogModule,
		MatSnackBarModule,
		UiModule,
		MatMenuModule,
		MatIconModule,
		MatBadgeModule,
		Angulartics2Module.forRoot(),
	],
	declarations: [
		AppComponent,
		IndexComponent,
		PageNotFoundComponent,
		RegisterComponent,
		LoginComponent,
		ResetPasswordComponent,
		SubRouterComponent,
		DashboardComponent,
		ClassesComponent,
		ClassComponent,
		PageComponent,
		ClassInfoComponent,
		DateTimeComponent,
		CurrencyComponent,
		CategoriesComponent,
		CategoryComponent,
		ClassesTableComponent,
		CartComponent,
		CheckoutComponent,
		LoginFormComponent,
		RegisterFormComponent,
		CheckoutAddressComponent,
		CheckoutListAddressesComponent,
		CheckoutItemsComponent,
		CheckoutPaymentFormComponent,
		PerformersAutocompleteComponent,
		PerformersComponent,
		PerformerComponent,
		PerformerClassesComponent,
		CreatePerformerComponent,
		CreatePerformerDialogComponent,
		OrderComponent,
		CartQuickPayComponent,
		OrdersComponent,
		OrderItemsComponent,
		OrderPaymentsComponent,
		ForgottenPasswordComponent,
		ConfirmDeleteDialogComponent,
		EntriesTableComponent,
		SchoolsRadioListComponent,
		EntriesComponent,
		AddToCartComponent,
		NavComponent,
		EntryComponent,
		WaitingListsComponent,
		PaymentMethodsComponent,
		AddressesComponent,
		PerformerInfoComponent,
		PerformerSchoolsComponent,
		PerformerEntriesComponent,
		PerformerQuickAddComponent,
		SecurityComponent,
		TwoFactorChallengeComponent,
		ConfirmAccountDetailsDialogComponent,
		PasskeysComponent,
		RenamePasskeyDialogComponent,
	],
	bootstrap: [AppComponent],
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		appRoutingProviders,
		Initialiser,
		HttpListener,
		BoundErrorhandler,
		FormBuilder,
		PermissionsService,
		AppService,
		AccountService,
		SystemConfigService,
		CartService,
		OrderGuard,
		LoggedOutGuard,
		LoggedInGuard,
		CartGuard,
		ClassService,
		CategoryService,
		AddressService,
		OrderService,
		PerformerService,
		CheckoutGuard,
		OrderItemService,
		SchoolsService,
		WaitingListService,
		WaitingListGuard,
		PaymentMethodService,
		PasskeysService,
		WebAuthnService,
	],
})
export class AppModule {}
