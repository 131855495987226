<span [formGroup]="form" *ngIf="form">
	<mat-form-field [ngStyle]="styles" subscriptSizing="dynamic" appearance="fill">
		<span matPrefix>{{ prefix }}</span>
		<mat-label>{{ placeHolder }}</mat-label>
		<textarea
			matInput
			[id]="inputId"
			[placeholder]="placeHolder"
			[formControlName]="formControlName"
			(ngModelChange)="writeValue($event)"
			[rows]="rows"
			[attr.disabled]="disabled"
			(keyup)="keyUpChange.emit()"
			[readonly]="disabled"
			ngDefaultControl
		></textarea>
		<span matSuffix>{{ suffix }}</span>
		<mat-error [ngStyle]="{ margin: '10px 0' }" *ngIf="error">{{ error }}</mat-error>
	</mat-form-field>
</span>
<span *ngIf="!form">
	<mat-form-field [ngStyle]="styles" subscriptSizing="dynamic" appearance="fill">
		<span matPrefix>{{ prefix }}</span>
		<mat-label>{{ placeHolder }}</mat-label>
		<textarea
			matInput
			[id]="inputId"
			[placeholder]="placeHolder"
			[(ngModel)]="value"
			(ngModelChange)="basicOnChange($event)"
			[rows]="rows"
			[disabled]="disabled"
			(keyup)="keyUpChange.emit()"
			(change)="blurOnChange($event.target)"
			[readonly]="disabled"
			ngDefaultControl
		></textarea>
		<span matSuffix>{{ suffix }}</span>
	</mat-form-field>
	<mat-error [ngStyle]="{ margin: '10px 0' }" *ngIf="error">{{ error }}</mat-error>
</span>
