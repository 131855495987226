import { Component, OnInit } from '@angular/core'
import Passkey from '@account/Passkey'
import { PasskeysService } from '@account/Services/PasskeysService'
import { Table } from '@ui/Table'
import HttpError from '@ui/HttpError'
import Pagination from '@ui/Pagination'
import { ConfirmDeleteDialogComponent } from '@account/Dialogs/ConfirmDeleteDialog.Component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { RenamePasskeyDialogComponent } from '@account/Dialogs/Security/RenamePasskeyDialog.Component'

@Component({
	selector: 'passkeys',
	templateUrl: './Passkeys.Component.html',
})
export class PasskeysComponent extends Table<Passkey> implements OnInit {
	public passkeys: Passkey[] = []

	private confirmDeleteDialogComponentMatDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>

	private renamePasskeyDialogComponentMatDialogRef: MatDialogRef<RenamePasskeyDialogComponent>

	constructor(
		public readonly passkeyService: PasskeysService,
		private readonly dialog: MatDialog,
	) {
		super()
	}

	public ngOnInit() {
		this.passkeyService.filterable = new Passkey(
			undefined,
			undefined,
			undefined,
			undefined,
		)

		this.passkeyService.clearLocalStorageKey().resetFilter()

		this.displayColumns = ['name', 'created', 'updated', 'rename', 'delete']

		this.tableEntities = [
			{
				columnDef: 'name',
				header: 'Name',
				accessorKey: 'name',
			},
			{
				columnDef: 'created',
				header: 'Created',
				type: 'datetime',
				accessorKey: 'created_at',
			},
			{
				columnDef: 'updated',
				header: 'Last Used',
				type: 'datetime',
				accessorKey: 'updated_at',
			},
			{
				columnDef: 'rename',
				header: 'Rename',
				type: 'action',
				action: {
					color: 'primary',
					title: 'Rename',
					output: this.rename,
				},
			},
			{
				columnDef: 'delete',
				header: 'Delete',
				type: 'action',
				action: {
					color: 'warn',
					title: 'Delete',
					output: this.delete,
				},
			},
		]

		this.getPasskeys()
	}

	public setPaginator = async (paginator: Pagination) => {
		this.passkeyService.setPaginator(paginator)

		await this.getPasskeys()
	}

	public getPasskeys = async () => {
		try {
			this.passkeys = await this.passkeyService.filter()

			this.setTableData(this.passkeys)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly delete = (passkey: Passkey): void => {
		this.confirmDeleteDialogComponentMatDialogRef = this.dialog.open(
			ConfirmDeleteDialogComponent,
		)

		this.confirmDeleteDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (confirm: boolean) => {
				if (confirm) {
					try {
						await this.passkeyService.deleteKey(passkey)

						this.passkeys = this.passkeys.filter((filter: Passkey) => {
							return passkey.id !== filter.id
						})

						this.setTableData(this.passkeys)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}
			})
	}

	private readonly rename = (passkey: Passkey): void => {
		this.renamePasskeyDialogComponentMatDialogRef = this.dialog.open(
			RenamePasskeyDialogComponent,
			{
				data: { passkey },
			},
		)

		this.renamePasskeyDialogComponentMatDialogRef.componentInstance.passkey =
			passkey

		this.renamePasskeyDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (passkey: Passkey) => {
				if (passkey) {
					try {
						const returnedKey = await this.passkeyService.update(passkey)

						this.passkeys = this.passkeys.map((filter: Passkey): Passkey => {
							if (filter.id === returnedKey.id) {
								return returnedKey
							}

							return filter
						})

						this.setTableData(this.passkeys)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}
			})
	}
}
