import { Component } from '@angular/core'
import { PermissionsService } from '@account/Services/PermissionsService'
import HttpError, { FormErrors } from '@ui/HttpError'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import { startRegistration } from '@simplewebauthn/browser'
import { WebAuthnService } from '@account/Services/WebAuthnService'

@Component({
	selector: 'security',
	templateUrl: './Security.Component.html',
	styleUrls: ['./Security.Component.css'],
})
export class SecurityComponent extends FormValidationComponent {
	public qrCode: string

	public secret: string

	public recoveryCodes: string[]

	public code: string

	public formErrors: FormErrors = {
		code: '',
	}

	public validationMessages: ValidationMessages = {
		code: {
			required: 'Two factor code is required.',
		},
	}

	public passkeyError: string | null = null

	public creatingKey = false

	constructor(
		public readonly permissionsService: PermissionsService,
		public readonly sanitizer: DomSanitizer,
		protected formBuilder: FormBuilder,
		private readonly webAuthnService: WebAuthnService,
	) {
		super(formBuilder)
	}

	public async enableTwoFactor(): Promise<void> {
		try {
			await this.permissionsService.enableTwoFactor()
			await this.fetchTwoFactorQrCode()
			await this.fetchTwoFactorSecret()
			await this.getRecoveryCodes()
		} catch (error) {
			if (error instanceof HttpError) {
				if (!(error instanceof HttpError)) {
					throw error
				}
			}
		}
	}

	public fetchTwoFactorQrCode = async (): Promise<void> => {
		try {
			const qr = await this.permissionsService.getTwoFactorQrCode()

			this.qrCode = qr.svg
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public fetchTwoFactorSecret = async (): Promise<void> => {
		try {
			const secret = await this.permissionsService.getTwoFactorSecret()

			this.secret = secret.secretKey
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public getRecoveryCodes = async (): Promise<void> => {
		try {
			this.recoveryCodes =
				await this.permissionsService.getTwoFactorRecoveryCodes()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public confirmTwoFactor = async (): Promise<void> => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			await this.permissionsService.confirmTwoFactor(
				this.form.get('code').value,
			)

			this.recoveryCodes = undefined
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, '')
					this.markInvalidFields()
				}
				return
			}

			throw error
		}

		await this.permissionsService.getPermissions()
	}

	public regenerateRecoveryCodes = async (): Promise<void> => {
		try {
			await this.permissionsService.regenerateTwoFactorRecoveryCodes()

			this.recoveryCodes =
				await this.permissionsService.getTwoFactorRecoveryCodes()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public disableTwoFactor = async (): Promise<void> => {
		try {
			await this.permissionsService.disableTwoFactor()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}

		await this.permissionsService.getPermissions()
	}

	public transform(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html)
	}

	public codeTracker = (index: number) => index

	public makePasskey = async () => {
		this.passkeyError = undefined

		this.creatingKey = true

		try {
			const optionsJSON = await this.webAuthnService.getOptions()

			const registrationResponse = await startRegistration({ optionsJSON })

			await this.webAuthnService.verify(registrationResponse)
		} catch (error) {
			if (error instanceof HttpError) {
				this.passkeyError = error.message
			} else if (error?.name === 'InvalidStateError') {
				this.passkeyError =
					'Error: Authenticator was probably already registered by user'
			} else {
				this.passkeyError = 'Passkeys could not be created. Please try again.'
			}
		}

		this.creatingKey = false
	}

	protected buildForm() {
		this.form = this.formBuilder.group({
			code: ['', [Validators.required]],
		})
	}
}
