import { Component, Input, OnInit } from '@angular/core'
import { ThemePalette } from '@angular/material/core'

@Component({
	selector: 'flat-button',
	templateUrl: './FlatButton.Component.html',
})
export class FlatButtonComponent implements OnInit {
	@Input() public styles: { [name: string]: number | string } = {}

	@Input() public color: ThemePalette

	@Input() public disabled: boolean

	public ngOnInit(): void {
		if (typeof this.styles.margin === 'undefined') {
			this.styles['margin'] = '5px 0 0 0'
		}
	}
}
