<div [ngStyle]="{ 'width': '500px', 'max-width': '100%' }">
	<dialog-container>
		<h4>Rename Passkey</h4>
		<text-input placeHolder="Name" [(value)]="name"></text-input>
	</dialog-container>
	<dialog-actions-container [styles]="{ display: 'block', 'margin-bottom': '10px' }">
		<div [ngStyle]="{float: 'right', 'text-align': 'right'}">
			<default-button color="primary" (click)="confirmAndClose()">Rename</default-button>
		</div>
	</dialog-actions-container>
</div>
