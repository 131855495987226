<div>
	<vip-paginator
		[paginator]="passkeyService.paginator"
		(pageChanged)="setPaginator($event)"
		[requesting]="passkeyService.loading"
	></vip-paginator>
	<cdk-table-component
		[displayColumns]="displayColumns"
		[entities]="tableEntities"
		[dataSource]="dataSource"
	></cdk-table-component>
</div>
