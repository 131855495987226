import { Component, Input } from '@angular/core'
import HttpError, { FormErrors } from '@ui/HttpError'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import { AccountService } from '@account/Services/AccountService'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { TwoFactorError } from '@account/Errors/TwoFactorError'
import { startAuthentication } from '@simplewebauthn/browser'
import { WebAuthnService } from '@account/Services/WebAuthnService'

@Component({
	selector: 'login-form',
	templateUrl: './LoginForm.Component.html',
	styleUrls: ['../../Auth.css'],
})
export class LoginFormComponent extends FormValidationComponent {
	@Input() public displayRegister: boolean

	@Input() private readonly loginSuccessful: () => void | Promise<void>

	@Input() private readonly showChallenge: () => void | Promise<void>

	public formErrors: FormErrors = {
		email: '',
		password: '',
	}

	public error: string | null = null

	protected validationMessages: ValidationMessages = {
		email: {
			required: 'Email is required',
		},
		password: {
			required: 'Password is required',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly accountService: AccountService,
		private readonly webAuthnService: WebAuthnService,
	) {
		super(formBuilder)
	}

	public login = async (
		email: InputValue,
		password: InputValue,
	): Promise<void> => {
		this.validateForm()

		if (this.form.valid) {
			try {
				await this.accountService.login(email as string, password as string)

				this.loginSuccessful()
			} catch (error) {
				if (error instanceof TwoFactorError) {
					this.showChallenge()

					return
				}

				if (error instanceof HttpError) {
					if (error.getStatus() === 419) {
						window.location.reload()

						return
					}

					this.formErrors = error.handleFormErrors(this.formErrors, '')
					this.markInvalidFields()
				} else {
					throw error
				}
			}
		}
	}

	public startWebAuthn = async () => {
		try {
			const optionsJSON = await this.webAuthnService.getOptions()

			// Pass the options to the authenticator and wait for a response
			const assertionResponse = await startAuthentication({ optionsJSON })

			await this.webAuthnService.login(assertionResponse)

			window.location.reload()
		} catch (error) {
			if (error instanceof HttpError) {
				this.error = error.message
			} else {
				this.error = 'An error occurred. Please try again.'
			}
		}
	}

	protected buildForm = (): void => {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required]],
		})
	}
}
